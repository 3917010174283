export default class TeamBlock {
  constructor(container, options) {
      const defaultOptions = {
        splide: '.splide',
        teamMembers: '.splide__slide',
        modalContainer: '.modal-container',
        closeButton: '.close',
        carousels: '.carousel'
      }

      this.options = Object.assign({}, defaultOptions, options)
      this.container = document.querySelector(container)
      this.splide = this.container.querySelectorAll(this.options.splide)
      this.teamMembers = this.container.querySelectorAll(this.options.teamMembers)
      this.carousels = this.container.querySelectorAll(this.options.carousels)

      this.modalContainer = this.container.querySelectorAll(this.options.modalContainer)

      this.carousels.forEach(carousel => {
        carousel.addEventListener('click', this.onClickTeamMember.bind(this))
      })

      this.setEvents()
  }

  setEvents() {
    this.initSplide()
  }
  
  initSplide() {
    this.splide.forEach(slide => {
      new Splide(slide, {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: false,
        autoplay: true,
        pauseOnHover: false
      }).mount()
    })
  }

  onClickTeamMember(event) {
    const clickedTab = event.target.closest(this.options.teamMembers)

    if (this.modalContainer !== null) {
      this.modalContainer.forEach(modal => {
        modal.classList.add('active')

        var modalCloseButton = modal.querySelectorAll('.close')
        var modalMember = modal.querySelector('.member-name')
        var modalRole = modal.querySelector('.role')
        var modalQuote = modal.querySelector('.quote')
        var modalSocial = modal.querySelector('.social')
        var modalImage = modal.querySelector('.image')

        modalMember ? modalMember.innerHTML = clickedTab.dataset.member : modalMember.innerHTML = ''
        modalRole ? modalRole.innerHTML = clickedTab.dataset.role : modalRole.innerHTML = ''
        modalQuote ? modalQuote.innerHTML = `&ldquo;${clickedTab.dataset.quote}&rdquo;` : modalQuote.innerHTML = ''
        modalSocial ? modalSocial.href = clickedTab.dataset.social : modalSocial.href = ''
        modalImage ? modalImage.src = clickedTab.dataset.image : modalSocial.src = ''

        modalCloseButton.forEach(button => {
          button.addEventListener("click", () => {
            modal.classList.remove('active')

            modalMember.innerHTML = ''
            modalRole.innerHTML = ''
            modalQuote.innerHTML = ''
            modalSocial.href = ''
            modalSocial.src = ''
          })
        })

      })
    }
  }
}